import React from "react"

const Logo = () => (
  <svg
    width="130"
    height="24"
    viewBox="0 0 130 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.74628 6.9016V13.0465C4.74628 13.4745 4.84564 13.7879 5.04436 13.9866C5.25836 14.1701 5.60994 14.2618 6.09908 14.2618H7.58946V16.9674H5.57172C2.86611 16.9674 1.51331 15.6528 1.51331 13.0236V6.9016H0V4.26477H1.51331V1.12351H4.74628V4.26477H7.58946V6.9016H4.74628Z"
      fill="#76ADFC"
    />
    <path
      d="M21.7067 10.3409C21.7067 10.7995 21.6761 11.2122 21.615 11.5791H12.3288C12.4052 12.4962 12.7262 13.2147 13.2918 13.7344C13.8573 14.2541 14.5529 14.514 15.3783 14.514C16.5706 14.514 17.419 14.0019 17.9234 12.9778H21.3857C21.0188 14.2006 20.3157 15.2095 19.2762 16.0044C18.2368 16.7839 16.9604 17.1737 15.4471 17.1737C14.2242 17.1737 13.1236 16.9062 12.1453 16.3712C11.1823 15.8209 10.4257 15.049 9.87536 14.0554C9.34036 13.0618 9.07285 11.9154 9.07285 10.6161C9.07285 9.30149 9.34036 8.1474 9.87536 7.15381C10.4104 6.16023 11.1594 5.39593 12.1224 4.86093C13.0854 4.32592 14.1936 4.05841 15.4471 4.05841C16.6547 4.05841 17.7323 4.31827 18.6801 4.83799C19.6431 5.35772 20.3844 6.09908 20.9042 7.0621C21.4392 8.00982 21.7067 9.10277 21.7067 10.3409ZM18.382 9.42377C18.3667 8.59833 18.0686 7.94104 17.4878 7.45189C16.9069 6.94745 16.1961 6.69524 15.3554 6.69524C14.5605 6.69524 13.8879 6.93981 13.3376 7.42896C12.8026 7.90282 12.474 8.56776 12.3517 9.42377H18.382Z"
      fill="#76ADFC"
    />
    <path
      d="M39.8248 4.08134C41.384 4.08134 42.6374 4.56285 43.5851 5.52586C44.5481 6.47359 45.0297 7.80347 45.0297 9.51549V16.9674H41.8196V9.95114C41.8196 8.95755 41.5674 8.2009 41.063 7.68118C40.5585 7.14617 39.8707 6.87867 38.9994 6.87867C38.1281 6.87867 37.4325 7.14617 36.9128 7.68118C36.4084 8.2009 36.1562 8.95755 36.1562 9.95114V16.9674H32.9461V9.95114C32.9461 8.95755 32.6939 8.2009 32.1895 7.68118C31.685 7.14617 30.9972 6.87867 30.1259 6.87867C29.2393 6.87867 28.5361 7.14617 28.0164 7.68118C27.512 8.2009 27.2598 8.95755 27.2598 9.95114V16.9674H24.0497V4.26477H27.2598V5.80101C27.6725 5.266 28.1998 4.84564 28.8419 4.53992C29.4992 4.2342 30.2176 4.08134 30.9972 4.08134C31.9908 4.08134 32.8773 4.29534 33.6569 4.72335C34.4365 5.13607 35.0403 5.73222 35.4683 6.5118C35.881 5.77808 36.4772 5.18957 37.2568 4.74628C38.0516 4.30299 38.9076 4.08134 39.8248 4.08134Z"
      fill="#76ADFC"
    />
    <path
      d="M51.2858 6.09908C51.6986 5.51822 52.2641 5.03671 52.9826 4.65456C53.7163 4.25713 54.5494 4.05841 55.4818 4.05841C56.5671 4.05841 57.5454 4.32592 58.4167 4.86093C59.3033 5.39593 59.9988 6.16023 60.5032 7.15381C61.023 8.13211 61.2828 9.27091 61.2828 10.5702C61.2828 11.8695 61.023 13.0236 60.5032 14.0325C59.9988 15.0261 59.3033 15.798 58.4167 16.3483C57.5454 16.8986 56.5671 17.1737 55.4818 17.1737C54.5494 17.1737 53.7239 16.9827 53.0055 16.6005C52.3023 16.2184 51.7291 15.7369 51.2858 15.156V23.0206H48.0758V4.26477H51.2858V6.09908ZM58.004 10.5702C58.004 9.80592 57.8435 9.14863 57.5225 8.59833C57.2168 8.03275 56.8041 7.60475 56.2843 7.31431C55.7799 7.02388 55.2296 6.87867 54.6334 6.87867C54.0526 6.87867 53.5023 7.03153 52.9826 7.33724C52.4781 7.62768 52.0654 8.05568 51.7444 8.62126C51.4387 9.18684 51.2858 9.85178 51.2858 10.6161C51.2858 11.3804 51.4387 12.0453 51.7444 12.6109C52.0654 13.1765 52.4781 13.6121 52.9826 13.9178C53.5023 14.2083 54.0526 14.3535 54.6334 14.3535C55.2296 14.3535 55.7799 14.2006 56.2843 13.8949C56.8041 13.5892 57.2168 13.1535 57.5225 12.588C57.8435 12.0224 58.004 11.3498 58.004 10.5702Z"
      fill="#76ADFC"
    />
    <path d="M66.8255 0V16.9674H63.6155V0H66.8255Z" fill="#76ADFC" />
    <path
      d="M69.1716 10.5702C69.1716 9.2862 69.4238 8.1474 69.9283 7.15381C70.448 6.16023 71.1435 5.39593 72.0148 4.86093C72.9014 4.32592 73.8873 4.05841 74.9726 4.05841C75.9204 4.05841 76.7458 4.24949 77.449 4.63164C78.1674 5.01378 78.7406 5.49529 79.1686 6.07616V4.26477H82.4016V16.9674H79.1686V15.1101C78.7559 15.7063 78.1827 16.2031 77.449 16.6005C76.7305 16.9827 75.8974 17.1737 74.9497 17.1737C73.8797 17.1737 72.9014 16.8986 72.0148 16.3483C71.1435 15.798 70.448 15.0261 69.9283 14.0325C69.4238 13.0236 69.1716 11.8695 69.1716 10.5702ZM79.1686 10.6161C79.1686 9.83649 79.0158 9.17156 78.71 8.62126C78.4043 8.05568 77.9916 7.62768 77.4719 7.33724C76.9522 7.03153 76.3942 6.87867 75.7981 6.87867C75.2019 6.87867 74.6516 7.02388 74.1472 7.31431C73.6428 7.60475 73.23 8.03275 72.909 8.59833C72.6033 9.14863 72.4505 9.80592 72.4505 10.5702C72.4505 11.3345 72.6033 12.0071 72.909 12.588C73.23 13.1535 73.6428 13.5892 74.1472 13.8949C74.6669 14.2006 75.2172 14.3535 75.7981 14.3535C76.3942 14.3535 76.9522 14.2083 77.4719 13.9178C77.9916 13.6121 78.4043 13.1841 78.71 12.6338C79.0158 12.0682 79.1686 11.3957 79.1686 10.6161Z"
      fill="#76ADFC"
    />
    <path
      d="M89.2742 6.9016V13.0465C89.2742 13.4745 89.3735 13.7879 89.5722 13.9866C89.7862 14.1701 90.1378 14.2618 90.627 14.2618H92.1174V16.9674H90.0996C87.394 16.9674 86.0412 15.6528 86.0412 13.0236V6.9016H84.5279V4.26477H86.0412V1.12351H89.2742V4.26477H92.1174V6.9016H89.2742Z"
      fill="#76ADFC"
    />
    <path
      d="M106.235 10.3409C106.235 10.7995 106.204 11.2122 106.143 11.5791H96.8566C96.9331 12.4962 97.2541 13.2147 97.8197 13.7344C98.3852 14.2541 99.0807 14.514 99.9062 14.514C101.098 14.514 101.947 14.0019 102.451 12.9778H105.914C105.547 14.2006 104.844 15.2095 103.804 16.0044C102.765 16.7839 101.488 17.1737 99.975 17.1737C98.7521 17.1737 97.6515 16.9062 96.6732 16.3712C95.7102 15.8209 94.9535 15.049 94.4033 14.0554C93.8682 13.0618 93.6007 11.9154 93.6007 10.6161C93.6007 9.30149 93.8682 8.1474 94.4033 7.15381C94.9383 6.16023 95.6873 5.39593 96.6503 4.86093C97.6133 4.32592 98.7215 4.05841 99.975 4.05841C101.183 4.05841 102.26 4.31827 103.208 4.83799C104.171 5.35772 104.912 6.09908 105.432 7.0621C105.967 8.00982 106.235 9.10277 106.235 10.3409ZM102.91 9.42377C102.895 8.59833 102.597 7.94104 102.016 7.45189C101.435 6.94745 100.724 6.69524 99.8833 6.69524C99.0884 6.69524 98.4158 6.93981 97.8655 7.42896C97.3305 7.90282 97.0019 8.56776 96.8796 9.42377H102.91Z"
      fill="#76ADFC"
    />
    <path
      d="M111.788 6.23666C112.2 5.56408 112.735 5.03671 113.393 4.65456C114.065 4.27242 114.83 4.08134 115.686 4.08134V7.45189H114.837C113.828 7.45189 113.064 7.68882 112.544 8.16269C112.04 8.63655 111.788 9.46199 111.788 10.639V16.9674H108.578V4.26477H111.788V6.23666Z"
      fill="#76ADFC"
    />
    <path
      d="M130 4.26477L122.135 22.9747H118.719L121.47 16.6464L116.38 4.26477H119.98L123.259 13.1383L126.584 4.26477H130Z"
      fill="#76ADFC"
    />
  </svg>
)

export default Logo
